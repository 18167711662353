// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `button {
    margin-bottom: 10px; /* Add margin to the bottom of both inputs and buttons */
}

button {
    padding: 10px 20px;
    font-size: 16px;
    background-color: rgb(200, 162, 200); /* Purple: Background color */
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

/* Hover effect */
button:hover {
    background-color: rgb(255, 218, 185); /* Peach: Change background color on hover */
}
`, "",{"version":3,"sources":["webpack://./src/components/Button/Button.module.css"],"names":[],"mappings":"AAAA;IACI,mBAAmB,EAAE,wDAAwD;AACjF;;AAEA;IACI,kBAAkB;IAClB,eAAe;IACf,oCAAoC,EAAE,6BAA6B;IACnE,WAAW;IACX,YAAY;IACZ,kBAAkB;IAClB,eAAe;AACnB;;AAEA,iBAAiB;AACjB;IACI,oCAAoC,EAAE,4CAA4C;AACtF","sourcesContent":["button {\n    margin-bottom: 10px; /* Add margin to the bottom of both inputs and buttons */\n}\n\nbutton {\n    padding: 10px 20px;\n    font-size: 16px;\n    background-color: rgb(200, 162, 200); /* Purple: Background color */\n    color: #fff;\n    border: none;\n    border-radius: 5px;\n    cursor: pointer;\n}\n\n/* Hover effect */\nbutton:hover {\n    background-color: rgb(255, 218, 185); /* Peach: Change background color on hover */\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
