import React, {ChangeEvent, useState} from "react";
import style from "./style.module.css";
import Input from "../components/Input";
import Checkbox from "../components/Checkbox";
import Button from "../components/Button";
import moneyFormatter from "../helpers/moneyFormatter";

export const TaxCalculator = () => {

    const getTaxCalculatorCalculator = async () => {
        const taxParametersGetValues: { [name: string]: any } = {
            // mortgage information
            income: taxCalculatorValues.income,
            filing_status: taxCalculatorValues.filingStatus,
            bonus: taxCalculatorValues.bonus,
            other_income: taxCalculatorValues.otherIncome,

            traditional_ira_contribution: taxCalculatorValues.traditionalIraContribution,
            traditional_401k_contribution: taxCalculatorValues.traditional401kContribution,
            health_care_contribution: taxCalculatorValues.healthCareContribution,

            short_term_capital_gains: taxCalculatorValues.shortTermCapitalGains,
            long_term_capital_gains: taxCalculatorValues.longTermCapitalGains,
            age: taxCalculatorValues.age,

            itemized_deductions: taxCalculatorValues.itemizedDeductions,
            standard_deductions: taxCalculatorValues.standardDeductions,

            // escrow_exists: escrowExists,
            // is_quarterly_property_tax: isQuarterlyPropertyTaxes,
        };

        let url_params: string[] = []
        Object.keys(taxParametersGetValues).forEach((key) => {
            url_params.push(`${key}=${taxParametersGetValues[key] != "" ? taxParametersGetValues[key] : 0}`)
        })
        const url = API_BASE_URL + '/api/tax_calculator'
        const response = await fetch(
            url + `?${url_params.join("&")}`
        );
        const data = await response.json();
        setTaxCalculator(data);
        setShouldShowCalculatorValues(true);
    }

    const initialTaxCalculatorValues = {
        income: 30,
        filingStatus: 1000000,
        bonus: 250000.0,
        otherIncome: 4.5,
        traditionalIraContribution: 500,
        traditional401kContribution: 850,
        healthCareContribution: 1300,
        shortTermCapitalGains: 10000,
        longTermCapitalGains: 10000,
        age: 10,
        itemizedDeductions: 7,
        standardDeductions: 46000,
    };

    const initialTaxCalculator: { [name: string]: number } = {}

    const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
        const {id, value} = event.target;
        setTaxCalculatorValues({...taxCalculatorValues, [id]: value});
    };

    // const handleCheckboxChange = (checked: boolean) => {
    //     setEscrowExists(checked);
    // };
    //
    // const handleQuarterlyCheckboxChange = (checked: boolean) => {
    //     setQuarterlyPropertyTaxes(checked);
    // }


    const [taxCalculatorValues, setTaxCalculatorValues] = useState(initialTaxCalculatorValues);
    const [taxCalculator, setTaxCalculator] = useState(initialTaxCalculator);
    // const [escrowExists, setEscrowExists] = useState(false);
    // const [isQuarterlyPropertyTaxes, setQuarterlyPropertyTaxes] = useState(false);
    const [shouldShowCalculatorValues, setShouldShowCalculatorValues] = useState(false);

    return (
        <div>
            <div className={style.flexContainer}>
                <div className={style.flexBox}>
                    <p className={style.header}>
                        Mortgage Information
                    </p>
                    <Input name="loanTerm"
                           label={"Loan Term (how long you have your loan)"}
                           placeholder={initialTaxCalculatorValues.income.toString()}
                           value={taxCalculatorValues.income}
                           onChange={handleInputChange}
                    />
                    <Input name="purchasePrice"
                           label={"Purchase Price"}
                           placeholder={initialTaxCalculatorValues.filingStatus.toString()}
                           value={taxCalculatorValues.filingStatus}
                           onChange={handleInputChange}
                    />
                    <Input name="downPayment"
                           label={"Down Payment"}
                           placeholder={initialTaxCalculatorValues.bonus.toString()}
                           value={taxCalculatorValues.bonus}
                           onChange={handleInputChange}
                    />
                    <Input name="mortgageInterestRate"
                           label={"Interest Rate"}
                           placeholder={initialTaxCalculatorValues.otherIncome.toString()}
                           value={taxCalculatorValues.otherIncome}
                           onChange={handleInputChange}
                    />
                </div>
                <div className={style.flexBox}>
                    <p className={style.header}>
                        Other Monthly Costs
                    </p>
                    <Input name="maintenanceFees"
                           label={"HOA/Maintenace Fees"}
                           placeholder={initialTaxCalculatorValues.traditionalIraContribution.toString()}
                           value={taxCalculatorValues.traditionalIraContribution}
                           onChange={handleInputChange}
                    />
                    {/*<Checkbox*/}
                    {/*    label="Do you have escrow?"*/}
                    {/*    checked={escrowExists}*/}
                    {/*    onChange={handleCheckboxChange}*/}
                    {/*/>*/}
                    {/*{escrowExists &&*/}
                    {/*    <Input name="escrowAmount"*/}
                    {/*           label={"What is your escrow amount?"}*/}
                    {/*           placeholder={initialTaxCalculatorValues.escrowAmount.toString()}*/}
                    {/*           value={taxCalculatorValues.escrowAmount}*/}
                    {/*           onChange={handleInputChange}*/}
                    {/*    />*/}
                    {/*}*/}
                </div>
            </div>
            <div className={style.flexContainer}>
                <div className={style.flexBox}>
                    <p className={style.header}>
                        Quarterly Costs
                    </p>
                    {/*{!escrowExists &&*/}
                    {/*    <div>*/}
                    {/*        <Input name="propertyTaxes"*/}
                    {/*               label={"Property Taxes"}*/}
                    {/*               placeholder={initialTaxCalculatorValues.propertyTaxes.toString()}*/}
                    {/*               value={taxCalculatorValues.propertyTaxes}*/}
                    {/*               onChange={handleInputChange}*/}
                    {/*        />*/}
                            {/*<Checkbox*/}
                            {/*    label={"I only know my quarterly payments"}*/}
                            {/*    onChange={handleQuarterlyCheckboxChange}*/}
                            {/*    checked={isQuarterlyPropertyTaxes}*/}
                            {/*/>*/}
                        {/*</div>*/}
                    {/*}*/}
                </div>
                <div className={style.flexBox}>
                    <p className={style.header}>
                        Annual Costs
                    </p>
                    {/*{!escrowExists &&*/}
                    {/*    <Input name="homeownersInsurance"*/}
                    {/*           label={"Home Owner's Insurance"}*/}
                    {/*           placeholder={initialTaxCalculatorValues.homeownersInsurance.toString()}*/}
                    {/*           value={taxCalculatorValues.homeownersInsurance}*/}
                    {/*           onChange={handleInputChange}*/}
                    {/*    />*/}
                    {/*}*/}
                    <Input name="annualMaintenanceCosts"
                           label={"Annual Maintenance Costs"}
                           placeholder={initialTaxCalculatorValues.traditional401kContribution.toString()}
                           value={taxCalculatorValues.traditional401kContribution}
                           onChange={handleInputChange}
                    />
                </div>
            </div>
            <div className={style.flexContainer}>
                <div className={style.flexBox}>
                    <p className={style.header}>
                        Life Time Costs
                    </p>
                    <Input name="closingCosts"
                           label="Closing Costs"
                           placeholder={initialTaxCalculatorValues.healthCareContribution.toString()}
                           value={taxCalculatorValues.healthCareContribution}
                           onChange={handleInputChange}
                    />
                    <Input name="numberOfYears"
                           label={"Number Of Years (how long do you plan to hold)"}
                           placeholder={initialTaxCalculatorValues.shortTermCapitalGains.toString()}
                           value={taxCalculatorValues.shortTermCapitalGains}
                           onChange={handleInputChange}
                    />
                    <Input name="marketReturn"
                           label={"Market Return (in Percent)"}
                           placeholder={initialTaxCalculatorValues.longTermCapitalGains.toString()}
                           value={taxCalculatorValues.longTermCapitalGains}
                           onChange={handleInputChange}
                    />

                    <Input name={"realEstateReturn"}
                           label={"Real Estate Return"}
                           placeholder={initialTaxCalculatorValues.age.toString()}
                           value={taxCalculatorValues.age}
                           onChange={handleInputChange}
                    />
                    <Input name={"inflationRate"}
                           label={"Inflation Rate"}
                           placeholder={initialTaxCalculatorValues.itemizedDeductions.toString()}
                           value={taxCalculatorValues.itemizedDeductions}
                           onChange={handleInputChange}
                    />
                    <Input name={"sellingCosts"}
                           label={"Selling Costs"}
                           placeholder={initialTaxCalculatorValues.standardDeductions.toString()}
                           value={taxCalculatorValues.standardDeductions}
                           onChange={handleInputChange}
                    />
                </div>
            </div>
            <Button onClick={() => getTaxCalculatorCalculator()}>Submit</Button>
            {shouldShowCalculatorValues &&
                // todo add tooltips on what all of these numbers are
                <div className={style.tableContainer}>
                    <table>
                        <tbody>
                        <tr>
                            <td>Monthly Mortgage Payment:</td>
                            <td>${moneyFormatter(taxCalculator["monthly_mortgage_payment"])}</td>
                        </tr>
                        <tr>
                            <td>Other Monthly Payments:</td>
                            <td>${moneyFormatter(taxCalculator["other_monthly_payments"])}</td>
                        </tr>
                        <tr>
                            <td>Total Monthly Payment:</td>
                            <td>${moneyFormatter(taxCalculator["total_monthly_payments"])}</td>
                        </tr>
                        </tbody>
                    </table>
                    <table>
                        <tbody>
                        <tr>
                            <td>Quarterly Costs:</td>
                            <td>${moneyFormatter(taxCalculator["quarterly_payments"])}</td>
                        </tr>
                        <tr>
                            <td>Annual Costs:</td>
                            <td>${moneyFormatter(taxCalculator["annual_costs"])}</td>
                        </tr>
                        <tr>
                            <td>Total Annual Costs:</td>
                            <td>${moneyFormatter(taxCalculator["total_annual_costs"])}</td>
                        </tr>
                        </tbody>
                    </table>
                    <table>
                        <tbody>
                        <tr>
                            <td>LifeTime Opportunity Cost:</td>
                            <td>${moneyFormatter(taxCalculator["lifetime_opportunity_cost"])}</td>
                        </tr>
                        <tr>
                            <td>Total Costs from Home Ownership:</td>
                            <td>${moneyFormatter(taxCalculator["total_cost_of_home_ownership"])}</td>
                        </tr>
                        <tr>
                            <td>Total Cost of Ownership (TCO):</td>
                            <td>${moneyFormatter(taxCalculator["total_cost_of_ownership_tco"])}</td>
                        </tr>
                        <tr>
                            <td>Total Monthly Costs:</td>
                            <td>${moneyFormatter(taxCalculator["total_monthly_costs_interpolated"])}</td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            }
        </div>
    )

}