// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.style_header__z5j9M {
  font-size: 50px;
  margin: 0;
}

.style_page__agOkw {
  text-align: center;
}

.style_financialCalculatorGroup__p\\+17h {
  padding: 30px;
}

.style_flexContainer__afPk- {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
}

.style_flexBox__b7WU- {
  flex: 1 1;
  margin: 30px;
  border: 1px solid deeppink;
  border-radius: 10px;
  padding-right: 10px;
  padding-bottom: 5px;
  padding-left: 10px;
}

.style_tableContainer__0z4TO {
  display: flex;
}

.style_tableContainer__0z4TO table {
  width: 33%; /* Adjust width as needed */
  margin-right: 10px; /* Adjust margin between tables */
  border: 1px solid deeppink;
  border-radius: 10px;
  padding: 10px;
}
`, "",{"version":3,"sources":["webpack://./src/pages/style.module.css"],"names":[],"mappings":";AACA;EACE,eAAe;EACf,SAAS;AACX;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,aAAa;EACb,eAAe;EACf,mBAAmB;AACrB;;AAEA;EACE,SAAO;EACP,YAAY;EACZ,0BAA0B;EAC1B,mBAAmB;EACnB,mBAAmB;EACnB,mBAAmB;EACnB,kBAAkB;AACpB;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,UAAU,EAAE,2BAA2B;EACvC,kBAAkB,EAAE,iCAAiC;EACrD,0BAA0B;EAC1B,mBAAmB;EACnB,aAAa;AACf","sourcesContent":["\n.header {\n  font-size: 50px;\n  margin: 0;\n}\n\n.page {\n  text-align: center;\n}\n\n.financialCalculatorGroup {\n  padding: 30px;\n}\n\n.flexContainer {\n  display: flex;\n  flex-wrap: wrap;\n  flex-direction: row;\n}\n\n.flexBox {\n  flex: 1;\n  margin: 30px;\n  border: 1px solid deeppink;\n  border-radius: 10px;\n  padding-right: 10px;\n  padding-bottom: 5px;\n  padding-left: 10px;\n}\n\n.tableContainer {\n  display: flex;\n}\n\n.tableContainer table {\n  width: 33%; /* Adjust width as needed */\n  margin-right: 10px; /* Adjust margin between tables */\n  border: 1px solid deeppink;\n  border-radius: 10px;\n  padding: 10px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"header": `style_header__z5j9M`,
	"page": `style_page__agOkw`,
	"financialCalculatorGroup": `style_financialCalculatorGroup__p+17h`,
	"flexContainer": `style_flexContainer__afPk-`,
	"flexBox": `style_flexBox__b7WU-`,
	"tableContainer": `style_tableContainer__0z4TO`
};
export default ___CSS_LOADER_EXPORT___;
