// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Apply some basic styling to the input */
input {
    margin-bottom: 10px; /* Add margin to the bottom of both inputs and buttons */
}

input {
    margin-left: 10px; /* Add side margin to inputs */
    padding: 10px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 5px;
    height: 19px;
}

/* Add some styles when the input is focused */
input:focus {
    outline: none;
    border-color: rgb(255, 182, 193); /* Pink: Change the border color on focus */
    box-shadow: 0 0 5px rgba(255, 182, 193, 0.5); /* Pink: Add a subtle box shadow */
}
`, "",{"version":3,"sources":["webpack://./src/components/Input/Input.module.css"],"names":[],"mappings":"AAAA,0CAA0C;AAC1C;IACI,mBAAmB,EAAE,wDAAwD;AACjF;;AAEA;IACI,iBAAiB,EAAE,8BAA8B;IACjD,aAAa;IACb,eAAe;IACf,sBAAsB;IACtB,kBAAkB;IAClB,YAAY;AAChB;;AAEA,8CAA8C;AAC9C;IACI,aAAa;IACb,gCAAgC,EAAE,2CAA2C;IAC7E,4CAA4C,EAAE,kCAAkC;AACpF","sourcesContent":["/* Apply some basic styling to the input */\ninput {\n    margin-bottom: 10px; /* Add margin to the bottom of both inputs and buttons */\n}\n\ninput {\n    margin-left: 10px; /* Add side margin to inputs */\n    padding: 10px;\n    font-size: 16px;\n    border: 1px solid #ccc;\n    border-radius: 5px;\n    height: 19px;\n}\n\n/* Add some styles when the input is focused */\ninput:focus {\n    outline: none;\n    border-color: rgb(255, 182, 193); /* Pink: Change the border color on focus */\n    box-shadow: 0 0 5px rgba(255, 182, 193, 0.5); /* Pink: Add a subtle box shadow */\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
