"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
Object.defineProperty(exports, "__esModule", { value: true });
var React = __importStar(require("react"));
var d3 = __importStar(require("d3"));
function lineChart(svgRef, lineData) {
    var today = new Date;
    var formatTime = d3.timeParse("%b %d %Y");
    function getFormattedDate(month) {
        var date = new Date(today.getFullYear(), today.getMonth() + 1, 1);
        var this_date = new Date(date.setMonth(today.getMonth() + parseInt(month)));
        var myarray = this_date.toString().split(/(\s+)/);
        var new_string = [myarray[2], myarray[4], myarray[6]].join(" ");
        return formatTime(new_string);
    }
    var data1 = [
        {
            name: "contributed",
            values: Object.keys(lineData["data"]).map(function (month) {
                var formatted_date = getFormattedDate(month);
                return {
                    date: formatted_date,
                    price: lineData["data"][month]["total_contributed"].toFixed(2)
                };
            })
        },
    ];
    var data2 = [
        {
            name: "compounding interest",
            values: Object.keys(lineData["data"]).map(function (month) {
                var formatted_date = getFormattedDate(month);
                return {
                    date: formatted_date,
                    price: lineData["data"][month]["compound_interest_value"].toFixed(2)
                };
            })
        },
    ];
    var svg = d3.select(svgRef.current);
    var width = 1000;
    var height = 400;
    var margin = 50;
    var duration = 250;
    // const tooltip = { width: 100, height: 100, x: 10, y: -30 };
    var lineOpacity = "1";
    var lineOpacityHover = "0.85";
    var otherLinesOpacityHover = "0.1";
    var lineStroke = "3.5";
    var lineStrokeHover = "5";
    var circleOpacity = "0.85";
    var circleOpacityOnLineHover = "0.85";
    var circleRadius = 5;
    var circleRadiusHover = 6;
    /* Scale */
    // @ts-ignore
    var _a = d3.extent(data1[0].values.slice(0, 1), function (d) { return d.date; }), minX = _a[0], notNeededMaxX = _a[1];
    var _b = d3.extent(data1[0].values.slice(-1), function (d) { return d.date; }), notNeededMinX = _b[0], maxX = _b[1];
    var xScale = d3
        .scaleTime()
        .domain([minX, maxX])
        .range([0, width - margin]);
    // @ts-ignore
    var _c = d3.extent(data1[0].values.slice(0, 1), function (d) { return d.price; }), minY = _c[0], _notNeededMaxY = _c[1];
    var _d = d3.extent(data2[0].values.slice(-1), function (d) { return d.price; }), _notNeededMinY = _d[0], maxY = _d[1];
    var yScale = d3
        .scaleLinear()
        .domain([minY, maxY])
        .range([height - margin, 0]);
    /* Add SVG */
    svg
        .attr("width", width + margin + "px")
        .attr("height", height + margin + "px")
        .append("g")
        .attr("transform", "translate(" + margin + ", " + margin + ")");
    var xAxis = d3
        .axisBottom(xScale)
        .tickSize(height - margin)
        .tickSizeOuter(0)
        // @ts-ignore
        .tickFormat(d3.timeFormat("%b %Y"))
        .tickPadding(15);
    var yAxis = d3
        .axisLeft(yScale)
        .tickSize(margin - width)
        .tickSizeOuter(0)
        .ticks(12)
        .tickPadding(20);
    // Add the X Axis
    svg
        .append("g")
        .attr("class", "x axis")
        .attr("transform", "translate(" + margin + ", " + margin + ")")
        .attr("font-weight", "100")
        .attr("font-family", '"Roboto", "sans-serif"')
        // @ts-ignore
        .call(xAxis);
    // Add the Y Axis
    svg
        .append("g")
        .attr("class", "y axis")
        .attr("transform", "translate(" + margin + ", " + margin + ")")
        .attr("font-weight", "100")
        .attr("font-family", '"Roboto", "sans-serif"')
        .call(yAxis)
        .append("text")
        .attr("y", 15)
        .attr("transform", "rotate(-90)");
    /* Add line into SVG */
    var line = d3
        .line()
        // @ts-ignore
        .x(function (d) { return xScale(d.date); })
        // @ts-ignore
        .y(function (d) { return yScale(d.price); });
    var lines = svg
        .append("g")
        .attr("class", "lines")
        .attr("transform", "translate(" + margin + ", " + margin + ")");
    // draws out line and different points
    lines
        .selectAll("line-group")
        .data(data1)
        .enter()
        .append("g")
        .attr("class", "line-group")
        // @ts-ignore
        .on("mouseover", function (_e, d) {
        svg
            .append("text")
            .attr("class", "title-text")
            .style("fill", "#33BBFF")
            .text(d.name)
            .attr("text-anchor", "middle")
            .attr("x", (width - margin) / 2)
            .attr("y", 70);
    })
        .on("mouseout", function (d) {
        svg.select(".title-text").remove();
    })
        .append("path")
        .attr("class", "line")
        // @ts-ignore
        .attr("d", function (d) { return line(d.values); })
        // line color that connects dots
        .style("stroke", "#33BBFF")
        .style("fill", "none")
        .style("opacity", lineOpacity)
        .on("mouseover", function () {
        d3.selectAll(".line").style("opacity", otherLinesOpacityHover);
        d3.selectAll(".circle").style("opacity", circleOpacityOnLineHover);
        // @ts-ignore
        d3.select(this)
            .style("opacity", lineOpacityHover)
            .style("stroke-width", lineStrokeHover)
            .style("cursor", "pointer");
    })
        .on("mouseout", function () {
        d3.selectAll(".line").style("opacity", lineOpacity);
        d3.selectAll(".circle").style("opacity", circleOpacity);
        // @ts-ignore
        d3.select(this).style("stroke-width", lineStroke).style("cursor", "none");
    });
    // /* Add circles in the line */
    lines
        .selectAll("circle-group")
        .data(data1)
        .enter()
        .append("g")
        .style("fill", "#33BBFF")
        .selectAll("circle")
        .data(function (d) { return d.values; })
        .enter()
        .append("g")
        .attr("class", "circle")
        // @ts-ignore
        .on("mouseover", function (_e, d) {
        // display amount on hover  ing of points
        // @ts-ignore
        d3.select(this)
            .style("cursor", "pointer")
            .append("text")
            .attr("class", "text")
            .text("" + d.price)
            // @ts-ignore
            .attr("x", function (d) { return xScale(d.date) + 5; })
            // @ts-ignore
            .attr("y", function (d) { return yScale(d.price) - 10; });
    })
        .on("mouseout", function () {
        // @ts-ignore
        d3.select(this)
            .style("cursor", "none")
            .transition()
            .duration(duration)
            .selectAll(".text")
            .remove();
    })
        .append("circle")
        // @ts-ignore
        .attr("cx", function (d) { return xScale(d.date); })
        // @ts-ignore
        .attr("cy", function (d) { return yScale(d.price); })
        .attr("r", circleRadius)
        .style("opacity", circleOpacity)
        .on("mouseover", function () {
        // @ts-ignore
        d3.select(this)
            .transition()
            .duration(duration)
            .attr("r", circleRadiusHover);
    })
        .on("mouseout", function () {
        // @ts-ignore
        d3.select(this).transition().duration(duration).attr("r", circleRadius);
    });
    lines
        .selectAll("line-group")
        .data(data2)
        .enter()
        .append("g")
        .attr("class", "line-group")
        // @ts-ignore
        .on("mouseover", function (_e, d) {
        svg
            .append("text")
            .attr("class", "title-text")
            .style("fill", "#AD33FFFF")
            .text(d.name)
            .attr("text-anchor", "middle")
            .attr("x", (width - margin) / 2)
            .attr("y", 70);
    })
        .on("mouseout", function (d) {
        svg.select(".title-text").remove();
    })
        .append("path")
        .attr("class", "line")
        // @ts-ignore
        .attr("d", function (d) { return line(d.values); })
        // line color that connects dots
        .style("stroke", "#AD33FFFF")
        .style("fill", "none")
        .style("opacity", lineOpacity)
        .on("mouseover", function () {
        d3.selectAll(".line").style("opacity", otherLinesOpacityHover);
        d3.selectAll(".circle").style("opacity", circleOpacityOnLineHover);
        // @ts-ignore
        d3.select(this)
            .style("opacity", lineOpacityHover)
            .style("stroke-width", lineStrokeHover)
            .style("cursor", "pointer");
    })
        .on("mouseout", function () {
        d3.selectAll(".line").style("opacity", lineOpacity);
        d3.selectAll(".circle").style("opacity", circleOpacity);
        // @ts-ignore
        d3.select(this).style("stroke-width", lineStroke).style("cursor", "none");
    });
    // /* Add circles in the line */
    lines
        .selectAll("circle-group")
        .data(data2)
        .enter()
        .append("g")
        .style("fill", "#AD33FFFF")
        .selectAll("circle")
        .data(function (d) { return d.values; })
        .enter()
        .append("g")
        .attr("class", "circle")
        // @ts-ignore
        .on("mouseover", function (_e, d) {
        // display amount on hover  ing of points
        // @ts-ignore
        d3.select(this)
            .style("cursor", "pointer")
            .append("text")
            .attr("class", "text")
            .text("" + d.price)
            // @ts-ignore
            .attr("x", function (d) { return xScale(d.date) + 5; })
            // @ts-ignore
            .attr("y", function (d) { return yScale(d.price) - 10; });
    })
        .on("mouseout", function () {
        // @ts-ignore
        d3.select(this)
            .style("cursor", "none")
            .transition()
            .duration(duration)
            .selectAll(".text")
            .remove();
    })
        .append("circle")
        // @ts-ignore
        .attr("cx", function (d) { return xScale(d.date); })
        // @ts-ignore
        .attr("cy", function (d) { return yScale(d.price); })
        .attr("r", circleRadius)
        .style("opacity", circleOpacity)
        .on("mouseover", function () {
        // @ts-ignore
        d3.select(this)
            .transition()
            .duration(duration)
            .attr("r", circleRadiusHover);
    })
        .on("mouseout", function () {
        // @ts-ignore
        d3.select(this).transition().duration(duration).attr("r", circleRadius);
    });
}
var LineGraph = function (_a) {
    var lineData = __rest(_a, []);
    var svg = React.useRef(null);
    React.useEffect(function () {
        lineChart(svg, lineData);
    }, [svg]);
    return React.createElement("svg", { ref: svg });
};
exports.default = LineGraph;
