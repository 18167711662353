// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Checkbox_container__BWOFX {
    display: flex;
    align-items: center;
}

.Checkbox_checkbox__b6b0y {
    margin: 10px;
}`, "",{"version":3,"sources":["webpack://./src/components/Checkbox/Checkbox.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,mBAAmB;AACvB;;AAEA;IACI,YAAY;AAChB","sourcesContent":[".container {\n    display: flex;\n    align-items: center;\n}\n\n.checkbox {\n    margin: 10px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `Checkbox_container__BWOFX`,
	"checkbox": `Checkbox_checkbox__b6b0y`
};
export default ___CSS_LOADER_EXPORT___;
