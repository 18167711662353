// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Slider_sliderLabel__rDpEW {
    margin-bottom: 10px;
}

.Slider_sliderValue__Tb7\\+Q {
    color: rgb(147, 112, 219);
    font-weight: bold;
}

.Slider_sliderInput__Gy518 {
    accent-color: pink;
    margin: 0 0 10px 10px;
    height: 19px;
    cursor: pointer;
}
`, "",{"version":3,"sources":["webpack://./src/components/Slider/Slider.module.css"],"names":[],"mappings":"AAAA;IACI,mBAAmB;AACvB;;AAEA;IACI,yBAAyB;IACzB,iBAAiB;AACrB;;AAEA;IACI,kBAAkB;IAClB,qBAAqB;IACrB,YAAY;IACZ,eAAe;AACnB","sourcesContent":[".sliderLabel {\n    margin-bottom: 10px;\n}\n\n.sliderValue {\n    color: rgb(147, 112, 219);\n    font-weight: bold;\n}\n\n.sliderInput {\n    accent-color: pink;\n    margin: 0 0 10px 10px;\n    height: 19px;\n    cursor: pointer;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"sliderLabel": `Slider_sliderLabel__rDpEW`,
	"sliderValue": `Slider_sliderValue__Tb7+Q`,
	"sliderInput": `Slider_sliderInput__Gy518`
};
export default ___CSS_LOADER_EXPORT___;
